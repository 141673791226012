import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json'
import { Button } from 'react-bootstrap';

import DataTablePagination from '../Pagination/DataTablePagination';



// export file component
const Export = ({ data }) => {
  const fileName = "download_data";

  const columnsToExclude = ['Useraddresses', 'UserOrders','fcm_token','password','User','last_login','last_login','total_spent'];
  const filterData = (data1) => {
    return data1.map((row) => {
      const filteredRow = { ...row };

      // Remove the unwanted columns
      columnsToExclude.forEach((col) => delete filteredRow[col]);
      if (filteredRow.Restaurant) {
        filteredRow.Restaurant = `${filteredRow.Restaurant.name}`;
      }
      if (filteredRow.OrderItems) {
        filteredRow.OrderItems  = filteredRow.OrderItems.map(item => {
          return `${item.Menu.item_name} (Q:${item.quantity})`;
        }).join(', '); // Joi
       
      }
      if (filteredRow.Useraddress) {
        filteredRow.contact_name	= `${filteredRow.Useraddress.contact_name	}`;
      }
      if (filteredRow.Useraddress) {
        filteredRow.contact_phone = `${filteredRow.Useraddress.contact_phone}`;
      }
      if (filteredRow.Useraddress) {
        filteredRow.Useraddress = `${filteredRow.Useraddress.address_one} ${filteredRow.Useraddress.address_two}`;
      }

      
      return filteredRow;
    });
  };
  const exportCSV = () => {
    const filteredData = filterData(data); 
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: filteredData, fileName, exportType });
  };

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div className="export-options d-flex align-items-center me-2">
      <div className="export-title small me-2">Download </div>
      <div className="btn-group">
        <Button variant="outline-light" onClick={() => exportCSV()}>
          CSV
        </Button>
        {/* <Button variant="outline-light" onClick={() => exportExcel()}>
          Excel
        </Button> */}
      </div>
    </div>
  );
};


// expanded component in mobile view
const expandedComponent = ({ data }) => {
  return (
    <ul className="data-details p-3 gap gy-1 border-bottom small">
      <li>
        <span className="data-title text-base fw-medium me-2">Name:</span>
        <span className="data-text text-light">{data.name}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Age:</span>
        <span className="data-text text-light">{data.age}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Position:</span>
        <span className="data-text text-light">{data.position}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Company:</span>
        <span className="data-text text-light">{data.company}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Start Date:</span>
        <span className="data-text text-light">{data.startDate}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Salary:</span>
        <span className="data-text text-light">{data.salary}</span>
      </li>
    </ul>
  );
};

// custom checkbox
const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check" id={rest.name}>
    <input
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
  </div>
));

function DataTableComponent({ data, columns, className, expandableRows, actions, tableClassName, selectableRows, searchTerm, searchList, drill = '', ...props }) {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState('');
  const [showItemPerPage, setShowItemPerPage] = useState(10);
  const [mobileView, setMobileView] = useState(false);
  // console.log(data)

  useEffect(() => { setTableData(data) }, [data])
  // filter items by name
  useEffect(() => {
    let filteredData
    if (drill) {
      // filteredData = data.filter(
      //   item => searchList.reduce((total, search) => total || item[drill][search].toLowerCase().includes(searchText.toLowerCase()), false)
      // );
      filteredData = data.filter(
        item => searchList.reduce((total, search) => {
          // Check if item[search] is not null or undefined before calling toLowerCase()
          // console.log(search)
          // console.log(item)
          if(searchText){
            if (item[search]) {
              console.log(item[search])
              console.log( item[search].toString())
              return total || item[search].toString().toLowerCase().includes(searchText.toLowerCase());
            }else{
             
              if(item[drill][search]){
                return total || item[drill][search].toLowerCase().includes(searchText.toLowerCase());
              }else{
                if(item['Restaurant'][search]){
                  return total || item['Restaurant'][search].toLowerCase().includes(searchText.toLowerCase());
                }else{
                  return total;
                }
                
              }
            }
          }else{
           
            if(item[drill][search]){
              return total || item[drill][search].toLowerCase().includes(searchText);
            }else{
              return total ;
            }
            
          }
          
          
        }, false)
      );
    } else {
      filteredData = data.filter(
        item => searchList.reduce((total, search) => {
          // Check if item[search] is not null or undefined before calling toLowerCase()
          if (item[search]) {
            return total || item[search].toString().toLowerCase().includes(searchText.toLowerCase());
          }
          return total;
        }, false)
      );
      // filteredData = data.filter(
      //   item => searchList.reduce((total, search) => total || item[search].toLowerCase().includes(searchText.toLowerCase()), false)
      // );
    }
    // console.log(filteredData);
    setTableData(filteredData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  // function to change the table design view to expanable under 1200 px
  const inputStyle = {
    width: `${(searchTerm.length + 10) * 10}px`, 
  };
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="data-table-wrapper">
      <div className="data-table-top">
        <div className="data-table-search">
          <input
            className="form-control"
            placeholder={`Search by ${searchTerm}`}
            type="text"
            style={inputStyle}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="data-table-action-wrap">
          {actions && <Export data={tableData} />}
          <div className="data-table-select">
            <select
              className="form-select"
              onChange={(e) => setShowItemPerPage(e.target.value)}
              value={showItemPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
            <span className="text">Per page</span>
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={tableData}
        className={tableClassName}
        noDataComponent={<div className="p-2">There are no records found.</div>}
        sortIcon={
          <div className="data-table-sorter"></div>
        }
        pagination
        expandableRowsComponent={expandedComponent}
        expandableRows={mobileView}
        selectableRows={selectableRows}
        selectableRowsComponent={customCheckbox}
        paginationComponent={({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage }) => (
          <div className="data-table-bottom">
            <DataTablePagination
              showItemPerPage={showItemPerPage}
              itemPerPage={rowsPerPage}
              totalItems={rowCount}
              paginate={onChangePage}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              setShowItemPerPage={setShowItemPerPage}
            />
          </div>
        )}
      />
    </div>
  )
}

export default DataTableComponent;
