import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Block } from "../components";
import Layout from "../layout/default";
import { FaUserCircle } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { IoIosAddCircle, IoIosCloseCircle } from "react-icons/io";
import { Modal, Button } from "react-bootstrap";
import { IoIosSearch } from "react-icons/io";
import { Icon } from "@mui/material";
import axios from "axios";
import { GoPencil } from "react-icons/go";

const RestaurantDetails = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [restaurantData, setRestaurantData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [allcategoryData, setAllCategoryData] = useState([]);
  const [menuData, setMenuData] = useState(null);
  const { restaurant_id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isEditingCommission, setIsEditingCommission] = useState(false);
  
  useEffect(() => {
    // Update filteredCategories whenever searchTerm changes
    const filtered = getUnassignedCategories().filter((category) =>
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [searchTerm, categoryData, allcategoryData]);

  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
const token=localStorage.getItem("token")
const fetchCategoryDetails = async () => {
  try {
    const response = await fetch(
      `${apiUrl}/api/Category/restaurant/${restaurant_id}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    const data = await response.json();
    setCategoryData(data.rows);
  } catch (error) {
    console.error("Error fetching category details:", error);
  }
};

  console.log(categoryData);
  useEffect(() => {
    const fetchRestaurantDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/Restaurant/${restaurant_id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        const data = await response.json();
        setRestaurantData(data);
        console.log(restaurantData);
      } catch (error) {
        console.error("Error fetching restaurant details:", error);
      }
    };
  
    const fetchAllCategoryDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/Category`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
        const data = await response.json();
        setAllCategoryData(data.rows);
      } catch (error) {
        console.error("Error fetching category details:", error);
      }
    };

    const fetchMenuDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/Menu/restaurant/${restaurant_id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        const data = await response.json();
        setMenuData(data.rows);
      } catch (error) {
        console.error("Error fetching menu details:", error);
      }
    };
    console.log(menuData);
    fetchRestaurantDetails();
    fetchAllCategoryDetails();
    fetchCategoryDetails();
    fetchMenuDetails();
  }, [restaurant_id]);
  const handleToggleEditCommission = () => {
    setIsEditingCommission(!isEditingCommission);
  };

  const [newCommission, setNewCommission] = useState("");
  const handleUpdateCommission = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/Restaurant/update/${restaurantData.restaurant_id}`,
        { admin_commission: newCommission }
      );
      if (response.status === 201) {
        setRestaurantData((prevData) => ({
          ...prevData,
          admin_commission: newCommission,
        }));
        setIsEditingCommission(false);
      }
    } catch (error) {
      console.error("Error updating commission:", error);
    }
  };

  const handleAddCategory = async (categoryId) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/Category/assign/restaurant`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            category_id: categoryId,
            restaurant_id: parseInt(restaurant_id),
          }),
        }
      );

      if (response.ok) {
        fetchCategoryDetails();
        console.log("Category added successfully");
      } else {
        console.error("Failed to add category");
      }
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleRemoveCategory = async (restaurantCategoryId) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/Category/restaurant/delete/${restaurantCategoryId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        fetchCategoryDetails();
        console.log("Category removed successfully");
      } else {
        console.error("Failed to remove category");
      }
    } catch (error) {
      console.error("Error removing category:", error);
    }
  };

  const getUnassignedCategories = () => {
    if (categoryData !== null) {
      return allcategoryData.map((category) => {
        const isAssigned = categoryData.some(
          (c) => c.category_id === category.category_id
        );
        return {
          ...category,
          isAssigned,
          restaurant_category_id: isAssigned
            ? categoryData.find((c) => c.category_id === category.category_id)
                .restaurant_categorie_id
            : null,
        };
      });
    } else {
      return [];
    }
  };
  console.log(getUnassignedCategories());
  const getUniqueCategories = () => {
    const uniqueCategories = [];
    if (categoryData !== null) {
      categoryData.forEach((category) => {
        if (
          !uniqueCategories.some((c) => c.category_id === category.category_id)
        ) {
          uniqueCategories.push(category);
        }
      });
    }

    return uniqueCategories;
  };
  console.log(getUniqueCategories());

  const formatTime = (timeString) => {
    try {
      const [hours, minutes] = timeString.split(":").slice(0, 2);
      const formattedTime = `${hours}:${minutes}`;
      return formattedTime;
    } catch (error) {
      console.error("Error formatting time:", error.message);
      return "Invalid Time";
    }
  };

  return (
    <Layout title="Users List" content="container">
      <div className="rest-det-flex">
        <div className="restauarant-details-flex">
          <div className="restaurant-details-container">
            {restaurantData ? (
              <>
                <div className="rest-image">
                  {restaurantData.RestaurantFiles &&
                    restaurantData.RestaurantFiles[0] && (
                      <img
                        className="restaurant-image"
                        src={`${apiUrl}/${restaurantData.RestaurantFiles[0].image}`}
                        alt="Restaurant"
                      />
                    )}
                </div>
                <div>
                  <div className="open-clos">
                    <h2>{restaurantData.name} </h2>
                    {restaurantData.is_open ? (
                      <p className="status-open">Open</p>
                    ) : (
                      <p className="status-closed">Closed</p>
                    )}
                  </div>
                  <p className="address">
                    {restaurantData.address}, {restaurantData.city},{" "}
                    {restaurantData.state}, {restaurantData.country}
                  </p>

                  {restaurantData.rating && (
                    <p>Rating: {restaurantData.rating}</p>
                  )}
                  <p>
                    Open Hours: {formatTime(restaurantData.timings_open)} to{" "}
                    {formatTime(restaurantData.timings_close)}
                  </p>
                  <div className="category-box">
                    {getUniqueCategories().length > 0 ? (
                      getUniqueCategories().map(
                        (category, index) =>
                          category.Category &&
                          category.Category.name && (
                            <div
                              key={category.category_id}
                              className="category-item1"
                            >
                              {category.Category.name}
                            </div>
                          )
                      )
                    ) : (
                      <p className="_5lxb">No categories assigned</p>
                    )}
                    <div style={{ fontSize: "22px" }}>
                      <IoIosAddCircle onClick={handleOpenModal} />
                    </div>
                  </div>

                  <Modal show={isModalOpen} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Add Categories
                        {isSearchOpen ? (
                          <input
                            className="_5ipl"
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        ) : null}
                        <IoIosSearch
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={handleToggleSearch}
                        />
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="category-box">
                        {filteredCategories.length > 0 ? (
                          filteredCategories.map((category) => (
                            <div
                              key={category.category_id}
                              className="category-item"
                            >
                              {category.isAssigned ? (
                                <button
                                  onClick={() =>
                                    handleRemoveCategory(
                                      category.restaurant_category_id
                                    )
                                  }
                                >
                                  {category.name}{" "}
                                  <IoIosCloseCircle style={{ color: "red" }} />
                                </button>
                              ) : (
                                <button
                                  onClick={() =>
                                    handleAddCategory(category.category_id)
                                  }
                                >
                                  {category.name} <IoIosAddCircle />
                                </button>
                              )}
                            </div>
                          ))
                        ) : (
                          <p>No categories available</p>
                        )}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </>
            ) : (
              <p>Loading restaurant details...</p>
            )}
          </div>
          <div className="menu-container">
            {menuData !== null && menuData.length > 0 ? (
              <div className="menu-rows">
                {menuData.map((menuItem, index) => (
                  <div key={menuItem.menu_id} className="menu-row">
                    {menuItem.ItemFiles && menuItem.ItemFiles[0] && (
                      <img
                        className="menu-image"
                        src={`${apiUrl}/${menuItem.ItemFiles[0].image}`}
                        alt={menuItem.name}
                      />
                    )}
                    <div className="order-card">
                      <h5 className="cardh5">
                        <span
                          className={
                            menuItem.food_type === "Non Veg"
                              ? "dot"
                              : "green-dot"
                          }
                        />
                        {menuItem.item_name}
                      </h5>
                      <div className="card-p-flex">
                        <p className="cardp">{menuItem.description}</p>
                        <p>₹{menuItem.price}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No menu items available.</p>
            )}
          </div>
        </div>
        <div className="seller-details-container">
          {restaurantData ? (
            <>
              <div className="seller-details">
                <h4 style={{ textAlign: "center" }}>Seller Details</h4>
                <p>
                  <FaUserCircle />
                  {restaurantData.owner_full_name}
                </p>
                <p>
                  <FaPhone />
                  {restaurantData.owner_number}
                </p>
                <p>
                  <MdMail />
                  {restaurantData.owner_email}
                </p>

                {restaurantData.admin_commission !== null && !isEditingCommission ? (
                  <div className="commission-container">
                    <p>Commission: {restaurantData.admin_commission}%</p>
                    <GoPencil onClick={handleToggleEditCommission} />
                  </div>
                ) : (
                  <div className="commission-container">
                    <input
                      type="text"
                      value={newCommission}
                      onChange={(e) => setNewCommission(e.target.value)}
                      placeholder="Enter commission "
                    />
                    <span>%</span>
                    <button className="save-comm" onClick={handleUpdateCommission}>Save</button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <p>Loading restaurant details...</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default RestaurantDetails;
