import { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Icon, Select } from '../../components';
import { userColumns } from '../../store/coupons/CouponsData';
import "../../assets/CouponsList.css";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';
function CouponsList() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { coupon_id } = useParams();
    console.log('kjkj', coupon_id)
    const [showModal, setShowModal] = useState(false);
    const [couponsData, setCouponsData] = useState([]);
    const handleShowModal = () => setShowModal(true);

    const [couponName, setCouponName] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [discountType, setDiscountType] = useState('');
    const [discountValue, setDiscountValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [editingCoupon, setEditingCoupon] = useState(null);
    const Navigate = useNavigate();
    const handleCloseModal = () => {
        setShowModal(false);
        Navigate('/coupons-manage/coupons-list')
        setCouponName('');
        setCouponCode('');
        setDiscountType('');
        setDiscountValue('');
        setStartDate('');
        setEndDate('');
        setEditingCoupon('');
        setFieldErrors({
            couponName: false,
            couponCode: false,
            discountType: false,
            discountValue: false,
            startDate: false,
            endDate: false,
        });
    };
    const [fieldErrors, setFieldErrors] = useState({
        couponName: false,
        couponCode: false,
        discountType: false,
        discountValue: false,
        startDate: false,
        endDate: false,
    });
    const validateForm = () => {
        const errors = {};

        if (!couponName) {
            errors.couponName = true;
        }

        if (!couponCode) {
            errors.couponCode = true;
        }

        if (!discountType) {
            errors.discountType = true;
        }

        if (!discountValue || (discountType === "2" && (Number(discountValue) < 0 || Number(discountValue) > 100))) {
            errors.discountValue = true;
        }

        if (!startDate) {
            errors.startDate = true;
        }

        if (!endDate) {
            errors.endDate = true;
        }

        setFieldErrors(errors);

        if (Object.values(errors).some((error) => error)) {
            toast.error('Please fill in all required fields correctly', {
                position: 'top-right',
            });
            return false;
        }

        return true;
    };
    const fetchCouponDetails = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/Coupon/${coupon_id}`);
            const coupon = response.data;

            setCouponName(coupon.name || '');
            setCouponCode(coupon.code || '');

            setDiscountType(coupon.discount_type || '');

            setDiscountValue(coupon.discount_value || '');
            setStartDate(new Date(coupon.start_time).toISOString().split('T')[0] || '');
            setEndDate(new Date(coupon.end_time).toISOString().split('T')[0] || '');



            setEditingCoupon(coupon);

            handleShowModal();
        } catch (error) {
            console.error('Error fetching coupon details:', error);
        }
    };

    console.log(editingCoupon)



    const postCouponData = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            const payload = {
                name: couponName,
                start_time: startDate,
                end_time: endDate,
                discount_type: discountType === "1" ? "Fixed" : "Percentage",
                discount_value: discountValue,
                code: couponCode
            };
            let response;

            if (editingCoupon) {

                response = await axios.put(`${apiUrl}/api/Coupon/update/${editingCoupon.coupon_id}`, payload);
            } else {

                response = await axios.post(`${apiUrl}/api/Coupon/Create`, payload);
            }
            console.log("Coupon created successfully:", response.data);

            const updatedResponse = await axios.get(`${apiUrl}/api/Coupon`);
            setCouponsData(updatedResponse.data.rows);


            handleCloseModal();
            setCouponName('');
            setCouponCode('');
            setDiscountType('');
            setDiscountValue('');
            setStartDate('');
            setEndDate('');
        } catch (error) {
            console.error("Error creating coupon:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/Coupon`);
                setCouponsData(response.data.rows);
                console.log(response.data.rows)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        if (coupon_id) {
            fetchCouponDetails();
        }
    }, [coupon_id]);

    console.log({ couponsData })

    const couponColumns = userColumns({ setCouponsData })

    console.log(discountType)

    return (
        <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Coupons List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="">Coupons Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Coupons</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                </Button>
                            </li>
                            <li>
                                <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add Coupon</span>
                                </Button>
                            </li>
                        </ul>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={couponsData} setCouponsData={setCouponsData} searchList={['name']} searchTerm='Coupon Name' columns={couponColumns} />
                </Card>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingCoupon ? 'Edit Coupon' : 'Add Coupon'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="couponname">Coupon Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="couponname"
                                            type="text"
                                            placeholder="Name"
                                            required 
                                            value={couponName}
                                            onChange={(e) => setCouponName(e.target.value)}

                                            className={fieldErrors.couponName ? 'is-invalid' : ''}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="couponcode">Coupon code</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="couponcode"
                                            type="text"
                                            placeholder="code"
                                            value={couponCode}
                                            onChange={(e) => setCouponCode(e.target.value)}
                                            required
                                            className={fieldErrors.couponCode ? 'is-invalid' : ''}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label>Discount type</Form.Label>
                                    <div className="form-control-wrap">
                                        <Select
                                            removeItemButton
                                            value={discountType}
                                            required
                                            className={fieldErrors.discountType ? 'is-invalid' : ''}
                                            onChange={(e) => {
                                                setDiscountValue('')
                                                setDiscountType(e.target.value)
                                            }}
                                        >
                                            <option value="">Select a type</option>
                                            <option value="1">Fixed</option>
                                            <option value="2">Percentage</option>
                                        </Select>
                                    </div>
                                    {fieldErrors.discountType && (
                            <div className="invalid-feedback">Please select a type.</div>
                        )}
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="discount">Discount value</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            className={`form-control-wrap-number ${fieldErrors.discountValue ? 'is-invalid' : ''}`}
                                            disabled={!discountType}
                                            id="discount"
                                            type="number"
                                            placeholder={discountType ? "discount" : "select discount type"}
                                            value={discountValue}
                                            required
                                            onChange={(e) => setDiscountValue(prev => discountType === "2" ? Number(e.target.value) <= 100 ? e.target.value : prev : e.target.value)}
                                        />

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="startDate">Start date</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="startDate"
                                            type="text"
                                            placeholder="yyyy-mm-dd"
                                            value={startDate}
                                            required
                                            className={fieldErrors.startDate ? 'is-invalid' : ''}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="endDate">End date</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="endDate"
                                            type="text"
                                            placeholder="yyyy-mm-dd"
                                            value={endDate}
                                            required
                                            className={fieldErrors.endDate ? 'is-invalid' : ''}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" onClick={postCouponData}>
                                            {editingCoupon ? 'Update Coupon' : 'Add Coupon'}
                                        </Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Cancel</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout>
    )
}

export const handleDeleteCoupon = async (couponId, setCouponsData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const confirmed = window.confirm('Are you sure you want to delete this coupon?');
    if (confirmed) {
        try {
            await axios.delete(`${apiUrl}/api/Coupon/delete/${couponId}`);
            console.log(`Coupon with ID ${couponId} deleted successfully.`);
            const updatedResponse = await axios.get(`${apiUrl}/api/Coupon`);
            setCouponsData(updatedResponse.data.rows);
        } catch (error) {
            console.error(`Error deleting coupon with ID ${couponId}:`, error);


            console.error(error.response);
            console.error(error.message);
        }
    }
};


export default CouponsList;