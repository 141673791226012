import { Row, Col, Card, Button, Dropdown, Table, Badge } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../layout/default';
import Block from '../components/Block/Block';
import DataTable from '../components/DataTable/DataTable';
import { Colors } from '../utilities/index';
import hexRGB from '../utilities/hexRGB';
import { Link } from 'react-router-dom';
import {  Form} from 'react-bootstrap';


function PriceMessages() {
  const serverApiUrl = process.env.REACT_APP_API_URL;
 
  const [allusers, setAllusers] = useState([]);

  useEffect(() => {
    const fetchuserlist = async () => {
      try {
        const response = await axios.get(`${serverApiUrl}/api/Admin/allpriceChangesList`);
        console.log(response.data);
        setAllusers(response.data);
      
      } catch (error) {
        console.error('Error fetching last ten transactions:', error);
      }
    };

    

    fetchuserlist();
   
  }, []);
  
  const userColumns = [
    {
      name: "Item Name",
      selector: (row) => row.item_name,
      cell: (row) => <span>{row.item_name}</span>,
      sortable: true,
    },
    {
        name: "Old Price",
        selector: (row) => row.price,
        cell: (row) => <span>{row.price}</span>,
        sortable: true,
        minWidth: "150px",
      },
      {
        name: "New Price",
        selector: (row) => row.new_price,
        cell: (row) => <span>{row.new_price}</span>,
        sortable: true,
        minWidth: "150px",
      },
      {
        name: "Restaurant",
        selector: (row) => row?.Restaurant?.name,
        cell: (row) => <span>{row?.Restaurant?.name}</span>,
        sortable: true,
        minWidth: "200px",
      },
      {
        name: "Restaurant owner number",
        selector: (row) => row?.Restaurant?.owner_number,
        cell: (row) => <span>{row?.Restaurant?.owner_number}</span>,
        sortable: true,
        minWidth: "200px",
      },
      {
        name: "Restaurant Mobile number",
        selector: (row) => row?.Restaurant?.mobile_no,
        cell: (row) => <span>{row?.Restaurant?.mobile_no}</span>,
        sortable: true,
        minWidth: "200px",
      },
      {
        name: "Category",
        selector: (row) => row?.Category?.name,
        cell: (row) => <span>{row?.Category?.name}</span>,
        sortable: true,
        minWidth: "200px",
      },
      
      {
        name: "Changed ON Date",
        selector: (row) => row.createdAt,
        cell: (row) => (
            <span>{new Date(row.createdAt).toDateString()}</span>

        ),
        sortable: true,
        minWidth: "200px",
    },
]

  return (
    <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Price Change Messages List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="">Send Message to Users</Link></li>
                            </ol>
                        </nav>
                        
                    </Block.HeadContent>
                    
                </Block.HeadBetween>
            </Block.Head>
           
             <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={allusers} columns={userColumns} searchList={['price','item_name','new_price']} searchTerm = 'Name'/>
                </Card>
            </Block>
             
    </Layout>
  )
}

export default PriceMessages;
