import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Dropdown, Button, Offcanvas, Alert } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import notificationSound from '../../../assets/images/mask/new-notification-female-voice.mp3';
import { Logo, Image, Icon, MediaAction, MediaGroup, MediaText, Media, LinkList, LinkListItem, CustomDropdownToggle, CustomDropdownMenu, Schedule } from '../../../components';
import axios from 'axios';
import Menu from './Menu'

import ToggleSidebar from '../Toggle/Sidebar'
import ToggleNavbar from '../Toggle/Navbar'

import { useLayout, useLayoutUpdate } from './../LayoutProvider'
import { Link } from 'react-router-dom';

function QuickNav({ className, ...props }) {
    const compClass = classNames({
        "nk-quick-nav": true,
        [className]: className,
    })
    return (
        <ul className={compClass}>{props.children}</ul>
    )
}

function QuickNavItem({ className, ...props }) {
    const compClass = classNames({
        "d-inline-flex": true,
        [className]: className,
    })
    return (
        <li className={compClass}>{props.children}</li>
    )
}

function Header() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [notificationsData, setNotificationsData] = useState([]);
    const navigate = useNavigate();
    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/Notification/list/`);
            setNotificationsData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const audio = new Audio();
    const showNotificationToast = (notification) => {
        toast.info(
            <div>
                <strong>{notification.notification_id}-{notification.message}</strong>


            </div>,
            {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                onClick: () => {

                    navigate(`/order-details/${notification.order_id}`);
                },
            }
        );

        audio.src = notificationSound;
        audio.play();
    };

    useEffect(() => {

        fetchData();

        const intervalId = setInterval(async () => {

            await fetchData();
        }, 20000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {

        const lastCheckTimestamp = localStorage.getItem("lastCheckTimestamp");
        const currentTimestamp = new Date().getTime();
        const newNotifications = notificationsData.filter(
            (notification) => !notification.is_view && new Date(notification.createdAt).getTime() > lastCheckTimestamp && new Date(notification.createdAt).getTime() <= currentTimestamp
        );

        // Show toast for each new notification
        newNotifications.forEach((notification) => {
            showNotificationToast(notification);
        });


        if (newNotifications.length > 0) {
            localStorage.setItem("lastCheckTimestamp", currentTimestamp);
        }
    }, [notificationsData]);



    const layout = useLayout();
    const layoutUpdate = useLayoutUpdate();

    const compClass = classNames({
        "nk-header nk-header-fixed": true,
        [`is-${layout.headerVariant}`]: layout.headerVariant,
    });

    const navClass = classNames({
        "nk-header-menu nk-navbar": true,
        "navbar-active": layout.headerActive,
        // eslint-disable-next-line
        "navbar-mobile": layout.headerTransition || eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
    });

    // offcanvas
    const handleOffcanvasClose = () => setShowOffcanvas(false);
    const handleOffcanvasShow = () => setShowOffcanvas(true);
    const handleNotificationLinkClick = async (notificationId) => {
        // Close the offcanvas
        handleOffcanvasClose();

        try {
            
            const response = await axios.put(`${apiUrl}/api/Notification/${notificationId}/view`);


            if (response.data && response.data.message === "notification was viewed") {

                console.log("Notification marked as viewed");
            } else {

                console.error("Unexpected API response:", response.data);
            }
        } catch (error) {

            console.error("Error marking notification as viewed:", error);
        }
    };

    const hasUnreadNotifications = notificationsData.some(notification => !notification.is_view);

    return (
        <>
            <div className={compClass}>
                <div className="container-fluid">
                    <div className="nk-header-wrap">
                        <div className="nk-header-logo">
                            <ToggleSidebar variant="zoom" icon='menu' />
                            <ToggleNavbar className="me-2" />
                            <Logo />
                        </div>
                        {layout.headerActive && <div className="navbar-overlay" onClick={layoutUpdate.headerMobile}></div>}
                        {/* <nav className={navClass}>
                    <Menu />
                </nav> */}
                        <div className="nk-header-tools">
                            <QuickNav>
                                <QuickNavItem>
                                    <button className="btn-icon btn btn-zoom btn-sm d-sm-none" onClick={handleOffcanvasShow}>
                                        <Icon name="bell"></Icon>
                                    </button>
                                    <button className="btn-icon btn btn-zoom btn-md d-none d-sm-inline-flex" onClick={handleOffcanvasShow}>
                                        <Icon name="bell"></Icon>
                                        {hasUnreadNotifications && <span className="notification-btn-dot"></span>}
                                    </button>

                                </QuickNavItem>
                                <Dropdown as={QuickNavItem}>
                                    <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>

                                        <div className="d-inline-flex d-sm-none">
                                            <Media shape="circle" size="md">
                                                <Image src='/images/avatar/profile.png' staticImage thumbnail />
                                            </Media>
                                        </div>
                                        <div className="d-none d-sm-flex">
                                            <Media shape="circle">
                                                <Image src='/images/avatar/profile.png' staticImage thumbnail />
                                            </Media>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-md" as={CustomDropdownMenu}>
                                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                                            <MediaGroup>
                                                <Media size="xl" shape="circle">
                                                    <Image src='/images/avatar/profile.png' staticImage thumbnail />
                                                </Media>
                                                <MediaText>
                                                    <div className="lead-text">Super Admin</div>
                                                    <span className="sub-text">Owner &amp; Founder</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                                            <LinkList>
                                                {/* <LinkListItem to="/admin/profile"><Icon name="user"></Icon><span>My Profile</span></LinkListItem>
                                        <LinkListItem to="/admin/profile"><Icon name="contact"></Icon><span>My Contacts</span></LinkListItem> */}
                                                <LinkListItem to="/admin/profile-settings"><Icon name="setting-alt"></Icon><span>Account Settings</span></LinkListItem>
                                            </LinkList>
                                        </div>
                                        <div className="dropdown-content dropdown-content-x-lg py-3">
                                            <LinkList>
                                                <LinkListItem onClick={() => { localStorage.removeItem("token") }} to="/auths/auth-login"><Icon name="signout"></Icon><span>Log Out</span></LinkListItem>
                                            </LinkList>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </QuickNav>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas className="offcanvas-size-lg" placement="end" show={showOffcanvas} onHide={handleOffcanvasClose}>
                <Offcanvas.Header closeButton className="border-bottom border-light">
                    <Offcanvas.Title>Recent Notification</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SimpleBar>
                        <Schedule>
                            {notificationsData.map((notification) => (
                                <Schedule.Item
                                    key={notification.notification_id}
                                    symbol={notification.is_view ? "" : "active"}
                                    handleNotificationLinkClick={() => handleNotificationLinkClick(notification.notification_id)}
                                    notification={notification}
                                >
                                    <span className="smaller">{new Date(notification.createdAt).toDateString()}</span>
                                    <div className="h6">{notification.notification_id}</div>
                                    <div>{notification.message}</div>
                                </Schedule.Item>

                            ))}
                        </Schedule>


                    </SimpleBar>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Header