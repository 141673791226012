import { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import DataTable from '../../../components/DataTable/DataTable';
import { Icon, Select } from '../../../components';
import { BiFoodTag } from "react-icons/bi";
import { GiHotSpices } from "react-icons/gi";
// import  { userColumns } from '../../store/users/UsersData';
import axios from 'axios';
import "./OrderDetails.css";
import Rating from '../../../components/Rating/Rating';


function OrderDetails() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const { order_id } = useParams();
    console.log(order_id)
    const [orderData, setOrderData] = useState([]);
    const [filteredOrdersdata, setFilteredOrderData] = useState([]);

    const fetchData = async () => {

        try {

            const response = await axios.get(`${apiUrl}/api/Order/${order_id}`, {
                headers: {
                    "Accept": "application/json"
                }
            });



            if (response.status === 200) {

                setOrderData(response.data);
                console.log('orderdata', response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [order_id]);



    return (
        <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Order Details</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Orders</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Oreder Details</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Block>
                <div className='orderdetails-details'>

                    <Card>
                        {/* <div className='orderdetails-details'> */}
                        <div className='orderdetails-details-side'>
                            <div style={{ padding: "5px 10px", display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <h3>{orderData.Restaurant && orderData.Restaurant.name}, <span style={{ fontSize: '15px', fontWeight: "normal" }}>On {new Date(orderData.order_date).toDateString()}</span></h3>

                                </div>


                                <p className={`badge text-bg-${true ? "success-soft" : "secondary-soft"}`} style={{ height: "22px" }}>
                                    {orderData.order_status}
                                </p>
                            </div>

                            {orderData.OrderItems && orderData.OrderItems.length > 0 ? (
                                orderData.OrderItems.map((item, itemIndex) => {
                                    console.log('item', item)
                                    return (
                                        <div className='orderdetails-items-container'>
                                            <div className='orderdetails-item'>
                                                <img width="110px" height="110px" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROrvctvq_VpMFBmNcqG8YaEXWSIGUgiM5khw&usqp=CAU' style={{ objectFit: "cover", borderRadius: "10px", margin: "8px" }} />
                                                <div style={{ width: "60%" }}>

                                                    <p key={itemIndex}>
                                                        <span className={item.Menu.food_type === 'Non Veg' ? 'dot' : 'green-dot'} />
                                                        {`${item.quantity} * ${item.Menu.item_name}`}
                                                    </p>
                                                    <p style={{ display: 'flex', fontSize: "13px", margin: "0px", padding: "0px" }}><span style={{ fontWeight: "bold", color: "grey" }}>preparation time:</span>{item.Menu && item.Menu.preparation_time} </p>
                                                    <p style={{ fontSize: "13px", margin: "0px", padding: "0px" }}>{item.Menu && item.Menu.description}</p>
                                                    {item.OrderItemAddons && item.OrderItemAddons.length > 0 && (
                                                        <div>
                                                            <p style={{ fontSize: "14px", margin: "0px", padding: "0px", fontWeight: "bold" }}>Addons:</p>
                                                            <ul className='addon-cl' style={{ listStyleType: "none", padding: 0 }}>
                                                                {item.OrderItemAddons.map((addon, addonIndex) => (
                                                                    <li key={addonIndex}>
                                                                        {`${addon.quantity} * ${addon.AddonItem.item_name}`}
                                                                        <span>  {addon.AddonItem.price && `-Rs.${addon.AddonItem.price}`}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}  </div>



                                                <div className='orderdetails-item-detail' style={{ margin: "auto", width: "20%", fontSize: "14px" }}>
                                                    {/* <p>servs - 2</p> */}
                                                    {/* <p>portion_size</p> */}
                                                    <p>{`${item.quantity} * Rs.${item.price}`}</p>
                                                </div>
                                            </div>
                                        </div>)
                                })
                            ) :
                                (
                                    <p>No items selected</p>
                                )}
                        </div>
                    </Card>
                    {/* <div className='vertcal-line'></div> */}
                    <div>
                        <Card className='orderdetails-bill-side'>
                            <h3>{orderData.User && orderData.User.full_name}</h3>
                        </Card>
                        <Card className='orderdetails-bill-side'>
                            <div style={{ padding: "15px 10px", display: "flex", justifyContent: "space-between" }}>
                                <h3>Order Summary</h3>
                                <p className={`badge text-bg-${orderData.payment_status === "Delivered" ? "success-soft" : "warning-soft"}`} style={{ height: "22px" }}>
                                    {orderData.payment_status === "Delivered" ? "Delivered" : 'Pending'}
                                </p>
                            </div>

                            <div>
                                <p className="bill-price-item"><span>Price </span> <span className='bill-price-amount'>Rs.{orderData.total_amount}</span></p>
                                <p className="bill-price-item"><span>discount amount </span><span className='bill-price-amount'>Rs.{orderData.discount_amount}</span></p>
                                <p className="bill-price-item"><span>delivery fee </span> <span className='bill-price-amount'>Rs.{orderData.delivery_fee}</span></p>
                                <p className="bill-price-item"><span>tax amount </span> <span className='bill-price-amount'>Rs.{orderData.tax_amount}</span></p>
                            </div>
                            {/* <p style={{padding:"10PX"}}>Payment Method - COD</p> */}
                        </Card>
                        <Card className='orderdetails-bill-side'>
                            <p className="bill-price-item"><span>total amount </span> <span className='bill-price-amount'>Rs.{orderData.final_amount}</span></p>
                        </Card>
                        <Card className='orderdetails-bill-side'>
                            <h3>Delivery address</h3>
                            <p className='address-item'><span style={{ fontWeight: "bold" }}>Adddress line</span>:{orderData.Useraddress && orderData.Useraddress.address_one},{orderData.Useraddress && orderData.Useraddress.address_two},{orderData.Useraddress && orderData.Useraddress.city}</p>
                            <p className='address-item'><span style={{ fontWeight: "bold" }}>Landmark</span>:{orderData.Useraddress && orderData.Useraddress.landmark}</p>
                            <p className='address-item'><span style={{ fontWeight: "bold" }}>Pincode</span>: {orderData.Useraddress && orderData.Useraddress.pincode}</p>
                        </Card>

                    </div>

                </div>
            </Block>

        </Layout>
    )
}

export default OrderDetails;