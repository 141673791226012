import { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { Icon, Select } from '../../components';
import { userColumns } from '../../store/surveys/SurveysData';
import axios from 'axios';
import { IoRemoveCircle } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
function SurveyList() {
    const serverApiUrl = process.env.REACT_APP_API_URL;
    const { survey_id } = useParams();
    console.log('sssss', survey_id)
    const [showModal, setShowModal] = useState(false);
    const [surveysData, setSurveysData] = useState([]);

    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([]);


    const handleShowModal = () => setShowModal(true);
    const [editingSurvey, setEditingSurvey] = useState(null);
    const Navigate = useNavigate();
    const handleCloseModal = () => {
        setShowModal(false);
        Navigate('/surveys-manage/surveys-list')
        setQuestion('');
        setAnswers(['']);
        setEditingSurvey('');
        setFieldErrors({
            question: false,
            
        });
    };
    const [fieldErrors, setFieldErrors] = useState({
        question: false,
        
    });
    const validateForm = () => {
        const errors = {};

        if (!question) {
            errors.question = true;
        }

        

        setFieldErrors(errors);

        if (Object.values(errors).some((error) => error)) {
           
            return false;
        }

        return true;
    };
    const handleAnswerChange = (index, value) => {
        const newAnswers = [...answers];
        newAnswers[index] = value;
        setAnswers(newAnswers);
    };

    const handleAddOption = () => {
        setAnswers([...answers, '']);
    };

    const handleRemoveOption = (index) => {
        const newAnswers = answers.filter((_, i) => i !== index);
        setAnswers(newAnswers);
    };

    const fetchSurveyDetails = async () => {
        try {
            const response = await axios.get(`${serverApiUrl}/api/Survey/${survey_id}`);
            const survey = response.data;
            console.log(survey);
            setQuestion(survey.question || '');
            setAnswers(survey.SurveyAnswers.map(answer => answer.answer) || []);


            setEditingSurvey(survey);

            handleShowModal();
        } catch (error) {
            console.error('Error fetching coupon details:', error);
        }
    };
console.log(editingSurvey)
const handleAddSurvey = async () => {
    if (!validateForm()) {
        return;
    }
    try {
        const payload = {
            question: question,
            answers: answers.map((answer, index) => {
                if (editingSurvey && editingSurvey.SurveyAnswers[index] && editingSurvey.SurveyAnswers[index].answer_id) {
                    // For existing answers
                    return {
                        answer_id: editingSurvey.SurveyAnswers[index].answer_id,
                        answer: answer.trim(),
                    };
                } else {
                    // For new answers
                    return {
                        answer: answer.trim(),
                    };
                }
            }),
        };

        let response;
        if (editingSurvey) {
            response = await axios.put(`${serverApiUrl}/api/Survey/update/${editingSurvey.survey_id}`, payload);
        } else {
            response = await axios.post(`${serverApiUrl}/api/Survey/create`, payload);
        }

        console.log('Survey added successfully:', response.data);
        const updatedResponse = await axios.get(`${serverApiUrl}/api/Survey/list`);
        setSurveysData(updatedResponse.data);
        setQuestion('');
        setAnswers([]);
        handleCloseModal();
    } catch (error) {
        console.error('Error adding survey:', error);
    }
};

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${serverApiUrl}/api/Survey/list`);
                setSurveysData(response.data);
                console.log(response.data)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        if (survey_id) {
            fetchSurveyDetails();
        }
    }, [survey_id]);

    console.log({ surveysData })
    const surveyColumns = userColumns({ setSurveysData })

    return (
        <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Surveys List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="">Surveys Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Surveys</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                </Button>
                            </li>
                            <li>
                                <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add Survey</span>
                                </Button>
                            </li>
                        </ul>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={surveysData} setSurveysData={setSurveysData}  searchList={['question']} searchTerm = 'Question' drill='survey' columns={surveyColumns} />
                </Card>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingSurvey ? 'Edit Survey' : 'Add Survey'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="12">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="question">Question</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control
                                            id="question"
                                            type="text"
                                            placeholder="Please enter the question"
                                            value={question}
                                            onChange={(e) => setQuestion(e.target.value)}
                                            className={fieldErrors.question ? 'is-invalid' : ''}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col lg="12">
                                <Form.Group className="form-group">
                                    <Form.Label>Options</Form.Label>
                                    <div className="form-control-wrap">
                                        {answers.map((answer, index) => (
                                            <div key={index} className="mb-2" style={{ display: "flex", alignItems: "center" }}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={`Enter option ${index + 1}`}
                                                    value={answer}
                                                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                                                />
                                                <div className="remove-circleicon" onClick={() => handleRemoveOption(index)}>
                                                    <IoRemoveCircle />
                                                </div>
                                            </div>
                                        ))}
                                        <Button variant="secondary" className='CiCirclePlusoption' onClick={handleAddOption}>
                                            <CiCirclePlus />Add Option
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Col>


                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">

                                        <Button variant="primary" onClick={handleAddSurvey}>
                                            {editingSurvey ? 'Update Survey' : 'Add Survey'}
                                        </Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Cancel</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout>
    )
}
export const handleDeleteSurvey = async (survey_id, setSurveysData) => {
    const serverApiUrl = process.env.REACT_APP_API_URL;
    const confirmed = window.confirm('Are you sure you want to delete this Survey?');
    if (confirmed) {
        try {
            await axios.delete(`${serverApiUrl}/api/Survey/delete/${survey_id}`);
            console.log(`survey with ID ${survey_id} deleted successfully.`);
            const updatedResponse = await axios.get(`${serverApiUrl}/api/Survey/list`);
            setSurveysData(updatedResponse.data);
        } catch (error) {
            console.error(`Error deleting survey with ID ${survey_id}:`, error);

          
            console.error(error.response); 
            console.error(error.message); 
        }
    }
};
export default SurveyList;