import { Row, Col, Card, Button, Dropdown, Table, Badge } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../layout/default';
import {
  Image,
  Icon,
  Media,
  MediaGroup,
  MediaText,
  Pureknob,
  CustomDropdownMenu,
  CustomDropdownToggle,
  OverlineTitle,
  ChartLegend
} from '../components';
import { ChartBar, ChartLine } from "../components/Chart/Charts"
import { Colors } from '../utilities/index';
import hexRGB from '../utilities/hexRGB';
import { Link } from 'react-router-dom';
import {  Form} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
// visitor chart data
let visitorsChartData = {
  labels: ["M", "T", "W", "T", "F", "S", "S"],
  yAxis: false,
  xGridColor: Colors.white,
  xGridBorderColor: Colors.white,
  datasets: [
    {
      label: "Visitors",
      borderColor: 'transparent',
      backgroundColor: hexRGB(Colors.info, .3),
      hoverBackgroundColor: Colors.info,
      borderWidth: 1,
      borderRadius: 10,
      borderSkipped: false,
      data: [600, 680, 470, 770, 570, 810, 670]
    }
  ]
};

// activity Chart
let activityChart = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  xAxis: false,
  yAxis: false,
  datasets: [
    {
      tension: .4,
      label: "Activity",
      borderColor: Colors.success,
      pointBackgroundColor: Colors.success,
      backgroundColor: hexRGB(Colors.success, 0.2),
      borderWidth: 2,
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: Colors.success,
      fill: true,
      data: [120, 160, 95, 105, 98, 99, 167, 140, 155, 267, 237, 250]
    }
  ]
};

// total Profit Chart
// let totalProfitChart = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//   stacked: true,
//   ticksValue: 'k',
//   borderDash: [8, 4],
//   xGridColor: Colors.white,
//   xGridBorderColor: Colors.white,
//   yGridBorderColor: Colors.white,
//   maxTicksLimit: 20,
//   datasets: [
//     {
//       borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50 },
//       backgroundColor: Colors.primary,
//       label: "Total Income",
//       borderSkipped: false,
//       data: [120, 160, 95, 105, 98, 99, 167, 140, 155, 267, 237, 250]

//     },
//     {
//       borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50 },
//       backgroundColor: Colors.success,
//       label: "Total Profit",
//       borderSkipped: false,
//       data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95]
//     },
//     {
//       borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50 },
//       backgroundColor: Colors.gray300,
//       label: "Total Expense",
//       borderSkipped: false,
//       data: [75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125]
//     }
//   ]
// };

// total sales knob chart


// total revenue Chart
let totalRevenueChart = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  yAxis: false,
  xAxis: false,
  datasets: [
    {
      tension: .4,
      label: "Total",
      borderColor: Colors.primary,
      backgroundColor: 'transparent',
      borderWidth: 4,
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      pointHoverBackgroundColor: Colors.primary,
      borderCapStyle: 'round',
      data: [12, 40, 13, 130, 70, 210]
    }
  ]
};

// sales analytics Chart
let salesAnalyticsChart = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  borderDash: [8, 4],
  maxTicksLimit: 10,
  ticksValue: 'k',
  xAxis: false,
  xGridBorderColor: Colors.white,
  yGridBorderColor: Colors.white,
  datasets: [
    {
      tension: .4,
      borderWidth: 3,
      borderColor: Colors.yellow,
      backgroundColor: hexRGB(Colors.yellow, 0.2),
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      pointHoverBackgroundColor: Colors.yellow,
      label: "Total Sales",
      fill: true,
      data: [40, 60, 30, 65, 60, 95, 90, 100, 96, 120, 105, 134]
    },
    {
      tension: .4,
      borderWidth: 2,
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      borderColor: Colors.danger,
      pointHoverBackgroundColor: Colors.danger,
      label: "Total Orders",
      borderDash: [8, 4],
      data: [70, 44, 49, 78, 39, 49, 39, 38, 59, 80, 56, 101]
    },
  ]
};

function HomeEcommerce() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [allTransactions, setAllTransactions] = useState([]);
  const [todayTransactions, setTodayTransactions] = useState([]);

  const [totalSales, setTotalSales] = useState({
    size: 120,
    value: 0,
    angleOffset: -0.5,
    angleStart: 0.5,
    angleEnd: 0.5,
    colorFg: Colors.info
  });

  useEffect(() => {
    const fetchData = async () => {
      try {

        const allTransactionsResponse = await axios.get(`${apiUrl}/api/Admin/transctions`);
        setAllTransactions(allTransactionsResponse.data);
        console.log("All Transactions:", allTransactionsResponse.data);
  
        const today = new Date().toISOString().split('T')[0];
        const todayTransactionsResponse = await axios.get(`${apiUrl}/api/Admin/transctions?from_date=${today}&to_date=${today}`);
        const todayTransactions = todayTransactionsResponse.data.rows;
  
        console.log("Today Transactions:", todayTransactions);
        setTodayTransactions(todayTransactions);
  
        setTotalSales((prevTotalSales) => ({
          ...prevTotalSales,
          value: todayTransactions.length
        }));
  
        console.log("Today Transactions Length:", todayTransactions.length);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  
  



  console.log({ allTransactions, todayTransactions, totalSales });

  const [lastTenTransactions, setLastTenTransactions] = useState([]);

  useEffect(() => {
    const fetchLastTenTransactions = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Admin/last_ten/transctions`);
        setLastTenTransactions(response.data.result);
      } catch (error) {
        console.error('Error fetching last ten transactions:', error);
      }
    };

    fetchLastTenTransactions();
  }, []);
console.log(lastTenTransactions)
  const totalProfitChartData = {
    labels: lastTenTransactions.map((transaction) => transaction.date),
    stacked: true,
    borderDash: [8, 4],
    xGridColor: Colors.white,
    xGridBorderColor: Colors.white,
    yGridBorderColor: Colors.white,
    maxTicksLimit: 20,
    datasets: [
      {
        borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50 },
        backgroundColor: Colors.primary,
        label: "Total Income",
        borderSkipped: false,
        data: lastTenTransactions.map((transaction) => transaction.totalAmount)
      },
      {
        borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50 },
        backgroundColor: Colors.success,
        label: "Total Commission",
        borderSkipped: false,
        data: lastTenTransactions.map((transaction) => transaction.totalCommission)
      },
     
    ]
  };
  const today = new Date();
  const to_date = today.toISOString().split('T')[0];
  const sixDaysBeforeToday = new Date(today.setDate(today.getDate() - 6));
    const from_date = sixDaysBeforeToday.toISOString().split('T')[0];
  const [fromDate, setFromDate] = useState(from_date); // Initialize with an empty string or a specific date string
  const [toDate, setToDate] = useState(to_date); // Initialize with an empty string or a specific date string

  // Handle date change
  const handleFromDateChange = (event) => {
    const selectedFromDate = event.target.value;
      setFromDate(event.target.value);
      console.log(selectedFromDate)
      console.log(toDate)
      if (selectedFromDate && toDate && toDate < selectedFromDate) {
        setToDate('');
        toast.error('To date is higher than From date');
     }
    };
    const handleToDateChange = (event) => {
      setToDate(event.target.value);
      const selectedToDate = event.target.value;
      console.log(selectedToDate)
      console.log(fromDate)
      if (fromDate && selectedToDate &&  selectedToDate < fromDate) {
        setToDate('');
        toast.error('To date is higher than From date');
     }
    };
  const get_transations_by_dates = async() =>{
    try {
      console.log(fromDate)
      const response = await axios.get(`${apiUrl}/api/Admin/last_ten/transctions?from_date=${fromDate}&to_date=${toDate}`);
      setLastTenTransactions(response.data.result);
    } catch (error) {
      console.error('Error fetching last ten transactions:', error);
    }

    try {

      const allTransactionsResponse = await axios.get(`${apiUrl}/api/Admin/transctions?from_date=${fromDate}&to_date=${toDate}`);
      setAllTransactions(allTransactionsResponse.data);
      console.log("All Transactions:", allTransactionsResponse.data);

      const today = new Date().toISOString().split('T')[0];
      const todayTransactionsResponse = await axios.get(`${apiUrl}/api/Admin/transctions?from_date=${today}&to_date=${today}`);
      const todayTransactions = todayTransactionsResponse.data.rows;

      console.log("Today Transactions:", todayTransactions);
      setTodayTransactions(todayTransactions);

      setTotalSales((prevTotalSales) => ({
        ...prevTotalSales,
        value: todayTransactions.length
      }));

      console.log("Today Transactions Length:", todayTransactions.length);

    } catch (error) {
      console.error('Error fetching data:', error);
    }


  }
  return (
    <Layout title="Dashboard">
      <Row className="g-gs">
        <Col xxl="12">
        <Row className="g-0 col-sep col-sep-md">
              <Col md="4">
              <Form.Control
                                            id="couponname"
                                            type="date"
                                            placeholder="Name"
                                            required 
                                            value={fromDate}
                                            onChange={(e) => handleFromDateChange(e)}

                                        />
              
              </Col>
              <Col md="4">
              <Form.Control
                                            id="toDate"
                                            type="date"
                                            placeholder="Name"
                                            required 
                                            value={toDate}
                                            onChange={(e) => handleToDateChange(e)}

                                        />
              
              </Col>
              <Col md="4">
                 <Button variant="primary" onClick={get_transations_by_dates}>Submit</Button>
              </Col>
        </Row>
        </Col>

        <Col xxl="8">
          <Card className="h-100">
            <Row className="g-0 col-sep col-sep-md">
              <Col md="8">
                <Card.Body>
                  <div className="card-title-group mb-4">
                    <div className="card-title">
                      <h4 className="title">Total Commission</h4>
                    </div>
                  </div>
                  <div className="nk-chart-ecommerce-total-profit">
                     <ChartBar data={totalProfitChartData} />
                  </div>
                </Card.Body>
              </Col>
              <Col md="4">
                <Card.Body>
                  <div className="total-profit-data">
                    <div className="amount-wrap pb-4">
                    {allTransactions &&
                    allTransactions.total_final_amount ? (
                      <div className="amount h2 mb-0 fw-bold" >₹ {allTransactions.total_final_amount.toFixed(2)}</div>
                      ) : (
                        <div className="amount h2 mb-0 fw-bold" >₹ 0</div>
                      )}
                      <span className="smaller">Total final amount</span>
                    </div>
                    <ul className="nk-data-list-group d-flex flex-column flex-sm-row flex-md-column gap g-4">
                      <li className="nk-data-list-item">
                        <Media shape="circle" variant="primary-soft">
                          <Icon name="coins"></Icon>
                        </Media>
                        <div className="amount-wrap">
                        {allTransactions &&
                        allTransactions.total_commission ? (
                          <div className="amount h3 mb-0">₹ {allTransactions.total_commission && allTransactions.total_commission.toFixed(2)}</div>
                        ) : (
                          <div className="amount h3 mb-0" >₹ 0</div>
                        )}
                          <span className="smaller">Total commission</span>
                        </div>
                      </li>
                      <li className="nk-data-list-item">
                        <Media shape="circle" variant="success-soft">
                          <Icon name="trend-up"></Icon>
                        </Media>
                        <div className="amount-wrap">
                          <div className="amount h3 mb-0">{allTransactions.count}</div>
                          <span className="smaller">Total orders</span>
                        </div>
                      </li>
                      {/* <li className="nk-data-list-item">
                                      <Media shape="circle" variant="secondary-soft">
                                        <Icon name="coin-alt"></Icon>
                                      </Media>
                                      <div className="amount-wrap">
                                          <div className="amount h3 mb-0">$12,836</div>
                                          <span className="smaller">Total Expense</span>
                                      </div>
                                  </li> */}
                    </ul>
                    <div className="pt-5">
                      <Link to="/transaction-manage/transaction-list">
                        <Button variant="primary">View Report</Button>
                      </Link>
                    </div>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xxl="4">
          <Row className="g-gs">
            {/* <Col sm="6" xl="4" xxl="6">
              <Card className="h-100">
                <Card.Body>
                  <Media shape="circle" variant="warning" className="mb-3">
                    <Icon name="trend-up"></Icon>
                  </Media>
                  <h5>Total Revenue</h5>
                  <div className="d-flex align-items-center mb-3">
                    <div className="amount h4 mb-0">
                    ₹ 
                      {todayTransactions.reduce((total, transaction) => {
                        const finalAmount = parseFloat(transaction.final_amount) || 0;
                        return total + finalAmount;
                      }, 0).toFixed(2)}
                    </div>
                  </div>
                  <p className="small">Daily Transactions</p>
                </Card.Body>
              </Card>
            </Col> */}

            <Col sm="6" xl="4" xxl="6">
              <Card className="h-100">
                <Card.Body>
                  <Media shape="circle" variant="success" className="mb-3">
                    <Icon name="sign-mxn"></Icon>
                  </Media>
                  <h5>Commission</h5>
                  <div className="d-flex align-items-center mb-3">
                    <div className="amount h4 mb-0">
                    ₹ 
                      {todayTransactions.reduce((total, transaction) => {
                        const finalAmount = parseFloat(transaction.total_admin_commission) || 0;
                        return total + finalAmount;
                      }, 0).toFixed(2)}
                    </div>
                  </div>
                  <p className="small">Today Commission</p>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="6" xl="4" xxl="6">
              <Card className="h-100">
                <Card.Body className="text-center">
                <div className="amount h4 mb-0"></div>
                  <Pureknob data={totalSales} className="nk-chart-ecommerce-knob" />
                  <p className="small mt-3">Total transactions today</p>
                </Card.Body>
              </Card>
            </Col>

         

          </Row>
        </Col>


      </Row>
    </Layout>
  )
}

export default HomeEcommerce;
