import { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import { userColumns } from '../../store/sellers/SellersData';
import { Dropdown } from "react-bootstrap";
import { MdVerified } from "react-icons/md";
import { MediaGroup, Media, MediaText, Image, Icon, Select, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
function SellerList() {
    const serverApiUrl = process.env.REACT_APP_API_URL;
    const [showModal, setShowModal] = useState(false);
    const [sellersData, setSellersData] = useState([]);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const [refresh, setRefresh] = useState(true);

    async function handleStatus(rowId, status) {
        const data = { 
            "is_active": status
        }
        try {
            const response = await axios.put(`${serverApiUrl}/api/Seller/update/${rowId}`, data, {
                headers: {
                    "Accept": "application/json"
                }
            })
            if (response.status === 201) {
                setRefresh(prev => !prev);
                console.log(refresh);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    async function handleverified(rowId,verified) {
        const data = {
            "is_verified": verified,
        }
        try {
            const response = await axios.put(`${serverApiUrl}/api/Seller/update/${rowId}`, data, {
                headers: {
                    "Accept": "application/json"
                }
            })
            if (response.status === 201) {
                setRefresh(prev => !prev);
                console.log(refresh);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const userColumns = [
        {
            name: "Restaurant",
            selector: (row) => (row.seller.Restaurants && row.seller.Restaurants.length > 0) ? row.seller.Restaurants[0].name : '',
            cell: (row) => (
                <Link to={`/restaurant-detail/${(row.seller.Restaurants && row.seller.Restaurants.length > 0) ? row.seller.Restaurants[0].restaurant_id : ''}`}>
                <span>
                    {row.seller.Restaurants && row.seller.Restaurants.length > 0 && (
                        <div>{row.seller.Restaurants[0].name}</div>
                    )}
                </span>
                </Link>
            ),
            sortable: true,
            minWidth: "130px",
        },

        {
            name: "Seller Details",
            grow: 2,
            selector: (row) => row.seller.full_name,
            cell: (row) => (
                <MediaGroup>
                    <Media size="md" shape="circle" className="user-pro" variant={row.theme && row.theme}>
                        {row.profile_picture ? (
                            <Image src={`${serverApiUrl}/${row.seller.profile_picture}`} staticImage />
                        ) : (
                            <span className="smaller fw-medium initials-container">
                                {row.seller.full_name && toInitials(row.seller.full_name)}
                            </span>
                        )}
                    </Media>
                    <MediaText>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <span>
                                {row.seller.full_name}
                            </span>
                            <span style={{ fontSize: '15px' }}>
                                {row.seller.is_verified &&
                                    <MdVerified style={{ color: "green" }} />
                                }
                            </span>
                        </div>
                        <span className="small text">{row.seller.email}</span>
                    </MediaText>
                </MediaGroup>
            ),
            sortable: true,
            minWidth: "320px",
        },


        {
            name: "Total orders",
            selector: (row) => row.total_orders,
            cell: (row) => (
                <Link to={`/order-manage/order-list/${(row.seller.Restaurants && row.seller.Restaurants.length > 0) ? row.seller.Restaurants[0].restaurant_id : ''}`}>
                <span>{row.total_orders}</span>
            </Link>
            
            ),
            sortable: true,
            minWidth: "150px",
        },
        {
            name: "Revenue till date",
            selector: (row) => row.total_orders_amount,
            cell: (row) => (
                <span>
                    {row.total_orders_amount > 0 ? `₹${row.total_orders_amount}` : '0'}
                </span>
            ),
            sortable: true,
            minWidth: "200px",
        },
        {
            name: "TDS total",
            selector: (row) => row.total_tax_amount,
            cell: (row) => (
                <span>
                    {row.total_tax_amount && parseFloat(row.total_tax_amount) > 0 
                        ? `₹${parseFloat(row.total_tax_amount).toFixed(2)}` 
                        : '0.00'}
                </span>
            ),
            sortable: true,
            minWidth: "200px",
        },
        {
            name: "Payble Amount Till Date",
            selector: (row) => row.total_payble_amount,
            cell: (row) => (
                <span>
                    {row.total_payble_amount && parseFloat(row.total_payble_amount) > 0 
                    ? `₹${parseFloat(row.total_payble_amount).toFixed(2)}` 
                    : '0.00'}
                </span>
            ),
            sortable: true,
            minWidth: "230px",
        },
        {
            name: "Current Admin Commission ",
            selector: (row) => (row.seller.Restaurants && row.seller.Restaurants.length > 0) ? row.seller.Restaurants[0].name : '',
            cell: (row) => (
                <span>
                    {row.seller.Restaurants && row.seller.Restaurants.length > 0 && (
                        <div>{row.seller.Restaurants[0].admin_commission	}</div>
                    )}
                </span>
            ),
            sortable: true,
            minWidth: "220px",
        },
        {
            name: "Current Admin GST ",
            selector: (row) => (row.seller.Restaurants && row.seller.Restaurants.length > 0) ? row.seller.Restaurants[0].name : '',
            cell: (row) => (
                <span>
                    {row.seller.Restaurants && row.seller.Restaurants.length > 0 && (
                        <div>{row.seller.Restaurants[0].admin_gst	}</div>
                    )}
                </span>
                
            ),
            sortable: true,
            minWidth: "220px",
        },
        {
            name: "Contact",
            selector: (row) => row.seller.phone_number,
            cell: (row) => (
                <span>{row.seller.phone_number}</span>
            ),
            sortable: true,
            minWidth: "200px",
        },
        {
            name: "Joined Date",
            selector: (row) => row.seller.createdAt,
            cell: (row) => (
                <span>{new Date(row.seller.createdAt).toDateString()}</span>

            ),
            sortable: true,
            minWidth: "200px",
        },
        {
            name: "Status",
            selector: (row) => row.seller.is_active,
            cell: (row) => (
                <span className={`badge text-bg-${row.seller.is_active ? "success-soft" : "secondary-soft"}`}>
                    {row.seller.is_active ? "Active" : "Inactive"}
                </span>
            ),
            sortable: true,
        },
        {
            name: "Documents",
            selector: (row) => row.seller.pan,
            cell: (row) => (
                <div>
                    {row.seller.license && (
                        <img
                            src={row.seller.license}
                            alt="License"
                            style={{ marginRight: "8px", maxWidth: "40px", maxHeight: "40px" }}
                        />
                    )}
                    {row.seller.aadhar && (
                        <img
                            src={row.seller.aadhar}
                            alt="Aadhar"
                            style={{ marginRight: "8px", maxWidth: "40px", maxHeight: "40px" }}
                        />
                    )}
                    {!row.seller.license && !row.seller.aadhar && (
                        <span className="badge text-bg-secondary-soft" style={{ marginRight: "8px" }}>Not uploaded</span>
                    )}
                </div>
            ),
            sortable: true,
        },



        {
            name: "action",
            cell: (row) => (

                <div className="text-end w-100">

                    <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                            <div className="dropdown-content py-1">

                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                    <Dropdown.Item onClick={() => { handleStatus(row.seller.seller_id, !row.seller.is_active) }} style={{ border: 'none', backgroundColor: 'white' }}>
                                        <Icon name="active"></Icon><span>{row.seller.is_active ? "Mark as Inactive" : "Mark as Active"}</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => { handleverified(row.seller.seller_id, !row.seller.is_verified) }} style={{ border: 'none', backgroundColor: 'white' }}>
                                        <Icon name="active"></Icon><span>{row.seller.is_verified ? "Mark as not verified" : "Mark as verified"}</span>
                                    </Dropdown.Item>


                                    {/* <LinkListItem to={`/user-manage/user-profile/${row.seller_id}`}>
                                        <Icon name="eye"></Icon>
                                        <span>Block</span>
                                    </LinkListItem> */}
                                </LinkList>

                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
            sortable: false,

        },

    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${serverApiUrl}/api/Admin/sellers`);
                setSellersData(response.data);
                console.log(response.data)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    //   console.log({sellersData})
    const { handleSubmit, handleChange, handleBlur, resetForm, values, errors, touched } = useFormik({
        initialValues: {
            full_name: "",
            phone_number: "",
            email: "",
            password: ""
        },
        validationSchema: Yup.object().shape({
            full_name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email').required('Required'),
            phone_number: Yup.number().typeError().positive().integer().min(10).required(),
            password: Yup.string().required('Required')
        }),
        onSubmit: values => {
            // console.log(values);
            submitData({
                full_name: values.full_name,
                phone_number: values.phone_number,
                email: values.email,
                password: values.password
            });
        }
    });

    async function submitData(data) {
        try {
            const response = await axios.post(`${serverApiUrl}/api/Seller/register`, data,);
            // console.log(response)
        } catch (error) {
            console.error("Error submiting data:", error);
            console.log(error.response.data.error);
            return toast.error(error.response.data.error);
        }

        resetForm();
        handleCloseModal();
        setRefresh(prev => !prev);
    }
    
    const [fromDate, setFromDate] = useState(''); // Initialize with an empty string or a specific date string
    const [toDate, setToDate] = useState(''); // Initialize with an empty string or a specific date string
  
    // Handle date change
    const handleFromDateChange = (event) => {
    const selectedFromDate = event.target.value;
      setFromDate(event.target.value);
      console.log(selectedFromDate)
      console.log(toDate)
      if (selectedFromDate && toDate && toDate < selectedFromDate) {
        setToDate('');
        toast.error('To date is higher than From date');
     }
    };
    const handleToDateChange = (event) => {
      setToDate(event.target.value);
      const selectedToDate = event.target.value;
      console.log(selectedToDate)
      console.log(fromDate)
      if (fromDate && selectedToDate &&  selectedToDate < fromDate) {
        setToDate('');
        toast.error('To date is higher than From date');
     }
    };
    const get_users_by_dates_clear = async() =>{
        setFromDate('');
        setToDate('');
        try {
            const response = await axios.get(`${serverApiUrl}/api/Admin/sellers`);
            setSellersData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const get_users_by_dates = async() =>{
      try {
        const response = await axios.get(`${serverApiUrl}/api/Admin/sellers?from_date=${fromDate}&to_date=${toDate}`);
        setSellersData(response.data);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    return (
        <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Seller List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/seller-manage/seller-list">seller Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Users</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Button className="d-md-none" size="md" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add</span>
                                </Button>
                            </li>
                            <li>
                                <Button className="d-none d-md-inline-flex" variant="primary" onClick={handleShowModal}>
                                    <Icon name="plus" />
                                    <span>Add Seller</span>
                                </Button>
                            </li>
                        </ul>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>
            <Col xxl="12">
                <Row className="g-0 col-sep col-sep-md">
                        <Col md="3">
                        <Form.Control
                                                    id="couponname"
                                                    type="date"
                                                    placeholder="Name"
                                                    required 
                                                    value={fromDate}
                                                    onChange={(e) => handleFromDateChange(e)}

                                                />
                        
                        </Col>
                        <Col md="3">
                        <Form.Control
                                                    id="toDate"
                                                    type="date"
                                                    placeholder="Name"
                                                    required 
                                                    value={toDate}
                                                    onChange={(e) => handleToDateChange(e)}

                                                />
                        
                        </Col>
                       
                        <Col md="2">
                            <Button variant="primary" onClick={get_users_by_dates}>Submit</Button>
                        </Col>
                        <Col md="2">
                            <Button variant="warning" onClick={get_users_by_dates_clear}>Clear</Button>
                        </Col>
                </Row>
            </Col>
            <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={sellersData} columns={userColumns} drill='seller' searchList={['full_name', 'email', 'phone_number']} searchTerm='Name | Email | Phone' />
                </Card>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Seller</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="fullname">First Name *</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control style={{ borderColor: errors.full_name && touched.full_name ? 'red' : '' }} id="fullname" type="text" name='full_name' placeholder="First name" onChange={handleChange} onBlur={handleBlur} value={values.full_name} />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="phone">Phone *</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control style={{ borderColor: errors.phone_number && touched.phone_number ? 'red' : '' }} id="phone" type="text" name='phone_number' placeholder="Last name" onChange={handleChange} onBlur={handleBlur} value={values.phone_number} />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="email">Email Address *</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control style={{ borderColor: errors.email && touched.email ? 'red' : '' }} id="email" type="text" placeholder="Email address" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="passeord">Password *</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control style={{ borderColor: errors.password && touched.password ? 'red' : '' }} id="password" type="text" name='password' placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" type='submit'>Add Seller</Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type='button' onClick={resetForm} className="border-0 btn">Cancel</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout>
    )
}

export default SellerList;